<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#13736f"
        spinner="spinner" />
      <v-flex xs12 text-center>
        <v-layout wrap pt-10 justify-center>
          <v-flex xs11 lg8 text-left>
            <span style="
                font-family: opensansregular;
                color: black;
                font-size: 30px;
                font-weight: bold;
              ">
              Account Management</span>
          </v-flex>
          <v-flex xs4 lg2>
            <span style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 15px;
                ">Welcome !</span><br />
            <span v-if="role === 'Trader'" style="
                  font-family: dmsansregular;
                  color: #29807C;
                  font-size: 22px;
                ">{{ traderName }}</span>

            <span v-if="role === 'Farmer'" style="
                  font-family: dmsansregular;
                  color: #29807C;
                  font-size: 22px;
                ">{{ farmerName }}</span>

            <span v-if="role === 'Customer'" style="
                  font-family: dmsansregular;
                  color: #29807C;
                  font-size: 22px;
                ">{{ customerName }}</span>
          </v-flex>
        </v-layout>

        <v-layout pb-7 pt-10 wrap justify-center>
          <v-flex xs11 lg2 text-left>
            <v-layout wrap>
              <v-flex xs12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 16px;
                  font-weight: bold;
                ">
                Manage My Account
              </v-flex>

              <v-flex pt-4 xs3 lg12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 14px;
                ">
                <a class="link" :class="{ 'active-link': currentComponent === 'myprofile' }" @click="showProfile">
                  My Profile
                </a>
              </v-flex>

              <v-flex pt-lg-2 xs4 lg12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 14px;
                ">
                <a class="link" :class="{ 'active-link': currentComponent === 'addressBook' }" @click="showAddressBook">
                  Address Book
                </a>
              </v-flex>

              <!-- <v-flex pt-2 xs3 lg12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 14px;
                ">
                <a class="link" :class="{
                  'active-link': currentComponent === 'PaymentOptions',
                }" @click="showPaymentoptions">
                  Payment Options
                </a>
              </v-flex> -->
              <v-flex pt-4 xs12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 16px;
                  font-weight: bold;
                ">
                Orders
              </v-flex>
              <v-flex pt-1 xs6 lg12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 14px;
                ">
                <a class="link" :class="{ 'active-link': currentComponent === 'myorders' }" @click="ShowOrders">
                  My Orders
                </a>
              </v-flex>

              <v-flex pb-lg-16 pt-lg-2 xs6 lg12 style="
                  font-family: dmsansregular;
                  color: black;
                  font-size: 14px;
                ">
                <a class="link" :class="{
                  'active-link': currentComponent === 'cancellations',
                }" @click="ShowCancellations">
                  Custom Order
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs11 lg8 text-left>
            <myprofile :is="currentComponent" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
      
<script>
import axios from "axios";
import myprofile from "./MyProfile.vue";
import addressBook from "./AddressBook.vue";
// import PaymentOptions from "./Paymentoptions.vue";
import myorders from "./MyOrders.vue";

import cancellations from "./customOrders.vue";

export default {
  components: {
    myprofile,
    addressBook,
    cancellations,
    myorders,
    // PaymentOptions,
  },
  data() {
    return {
      currentComponent: myprofile,
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      deliveryAddress: {},
      list: [],
      showid: {},
      removedialogue: false,
      selectedAddress: {},
      delivery: {},
      limit: 10,
      selectedAddressIndex: -1,
      appLoading: false,
      pages: 0,
      orderId: {},
      icon: null,
      profile: {},
      farmerName: {},
      traderName: {},
      customerName: {},
      farmeremail: {},
      role: "",
      phonenumber: {},
      customeremail: {},
      cart: [],
      showModal: true,
      item: [],
      dialog2: false,
    };
  },

  mounted() {
    this.getProfile();
    this.currentComponent = localStorage.getItem("currentComponent");

  },


  methods: {
    showProfile() {
      this.currentComponent = "myprofile";
      localStorage.setItem("currentComponent", "myprofile"); 

    },
    showAddressBook() {
      this.currentComponent = "addressBook";
      localStorage.setItem("currentComponent", "addressBook");

    },
    // showPaymentoptions() {
    //   this.currentComponent = "PaymentOptions";
    // },

    ShowOrders() {
      this.currentComponent = "myorders";
      localStorage.setItem("currentComponent", "myorders");

    },
    ShowCancellations() {

      this.currentComponent = "cancellations";
      localStorage.setItem("currentComponent", "cancellations");

    },

    clearSelectedAddress() {
      this.selectedAddressIndex = -1;
      this.selectedAddress = null;
    },


    getProfile() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.profile = response.data.data;
          this.phonenumber = this.profile.phone;
          this.role = this.profile.role;

          if (this.profile.traderId) {
            this.traderName = this.profile.traderId.name;
            this.traderemail = this.profile.traderId.email;
          }

          if (this.profile.farmerId) {
            this.farmerName = this.profile.farmerId.name;
            this.farmeremail = this.profile.farmerId.email;

          }

          if (this.profile.customerId) {
            this.customerName = this.profile.customerId.name;
            this.customeremail = this.profile.customerId.email;

          }
          this.appLoading = false;


        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },






  },
};
</script>
      
      
<style scoped>
.link {
  color: black;
}



.active-link {
  color: #29807c;
}
</style>